
import { BIconX } from 'bootstrap-vue'
export default {
  components: {
    BIconX
  },
  data () {
    return { msg: '', header: '', show: false }
  }
}
