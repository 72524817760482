
// import jsCookie from 'js-cookie'
import { mapGetters, mapState, mapMutations } from 'vuex'
import ModalAuthen from '~/components/modal/authen.vue'

export default {
  components: {
    ModalAuthen
  },
  data () {
    return {
      keywordText: '',
      userProfile: {},
      menus: [
        {
          text: 'navbar.all_products',
          to: { name: 'products' },
          new: false
        },
        {
          text: 'navbar.product_samples',
          to: { name: 'sample-products-category', params: { category: 0 } }
        },
        // {
        //   text: 'navbar.product_groups',
        //   to: { name: 'product-groups-category', params: { category: 0 } },
        //   new: false
        // },
        {
          text: 'navbar.my_design',
          to: { name: 'my-designs', query: { flow: 'create' } },
          new: true
        },
        {
          text: 'navbar.faq',
          to: { name: 'faq' },
          new: false
        },
        {
          text: 'navbar.articles',
          to: { name: 'articles', query: { type: 'all' } },
          new: false
        }
      ]
    }
  },
  computed: {
    ...mapState(['userData', 'showModalRequireLogin']),
    ...mapGetters(['isAuthenticated', 'isAuthenticating'])
  },
  watch: {
    showModalRequireLogin (val) {
      if (val) {
        // console.log('showModalRequireLogin', val)
        this.showModal('signin')
      }
    }
  },
  async mounted () {
    // console.log('isAuthhhh', this.isAuthenticated)
    if (this.isAuthenticated) {
      try {
        const res = await this.$axios.get('me')
        this.userProfile = res.data.user
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('nav mounted fail /me line')
        // console.log(error)
        this.$cookies.remove('token_' + process.env.NODE_ENV)
        this.signout()
      }
    }
  },
  methods: {
    ...mapMutations(['showLoading', 'hideLoading']),
    showModal (type) {
      this.$refs['modal-authen'].authenType = type
      this.$refs['modal-authen'].show()
    },
    async signout () {
      localStorage.setItem('back_url', this.$router.push('auth/login-via-phone'))
      await this.$store.dispatch('logout')

      // const routeName = String(this.$nuxt.$route.path)
      // await this.showLoading()

      // await this.$store.dispatch('logout')
      // if (routeName.includes('my/order') || routeName.includes('my/profile') || routeName.includes('my/cart')) {
      //   window.location = '/'
      // } else {
      //   await this.hideLoading()
      //   // window.location.reload()
      // }
    },
    logout () {
      if (window.liff.isLoggedIn()) {
        window.liff.logout()
        alert('logout done')
      }
    },
    gotoLoginPage () {
      const redirectUrl = localStorage.getItem('redirect_url')
      if (redirectUrl) {
        this.$router.push(this.localeLocation({ name: 'auth-login-via-phone', query: { redirect: redirectUrl } }))
      } else {
        this.$router.push(this.localeLocation({ name: 'auth-login-via-phone' }))
      }
    }
  }
}
