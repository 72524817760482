
// import { formatDateToYMD } from '../../plugins/helpers'
export default {
  data () {
    return {
      gotoLink: 'https://bit.ly/4eb79HJ',
      customWidth: 1200,
      width: '100%',
      height: '90%',
      url: '',
      // imageUrl: 'https://locopack-files.s3.ap-southeast-1.amazonaws.com/homepage/banners/01-Square_1200x1200.png',
      // desktopImageUrl: 'https://locopack-files.s3.ap-southeast-1.amazonaws.com/homepage/banners/01-Square_1200x1200.png',
      // mobileImageUrl: 'https://locopack-files.s3.ap-southeast-1.amazonaws.com/homepage/banners/04-Mobile+740x300.png'
      imageUrl: 'homepage/banners/01-Square_1200x1200.png',
      desktopImageUrl: 'homepage/banners/01-Square_1200x1200.png',
      mobileImageUrl: 'homepage/banners/04-Mobile+740x300.png'
    }
  },
  // computed: {
  //   computedImageUrl () {
  //     return window.innerWidth >= 768 ? this.desktopImageUrl : this.mobileImageUrl
  //   }
  // },
  mounted () {
  },
  methods: {
    async modalTemplateBoxShow () {
      await this.$firebasePlugin()
      this.$refs['modal-box-template'].show()
      // console.log('ref', this.$refs['modal-box-template'])
      // this.$refs['modal-box-template'].modal('show');
    }
  }
}
