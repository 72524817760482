
import { BIconX } from 'bootstrap-vue'
export default {
  components: {
    BIconX
  },
  data () {
    return {
      ModalProduct: {},
      pathMode: 'SUB_PATH'
    }
  },
  computed: {
    s3Path () {
      if (this.pathMode !== 'FULL_PATH') {
        return this.$store.state.aws_s3
      }
      return ''
    }
  }
}
