
import { BIcon } from 'bootstrap-vue'
export default {
  name: 'NeedHelpPopup',
  components: {
    BIcon
  },
  data () {
    return {
      link: 'https://lin.ee/bkli1RI'
    }
  }
}
