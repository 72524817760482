
export default {
  name: 'NeedHelpButton',
  props: {
    btnStyle: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      link: 'https://lin.ee/bkli1RI'
    }
  }
}
