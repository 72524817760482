
import { BIconX, BIconStarFill, BIconStarHalf, BIconCheck } from 'bootstrap-vue'
export default {
  components: {
    BIconX,
    BIconStarFill,
    BIconCheck,
    BIconStarHalf
  },
  props: {
    value: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      show: false,
      ModalProduct: {},
      pathMode: 'SUB_PATH',
      factoryList: [],
      packaging_type_id: null,
      factorySelected: null,
      img: null
    }
  },
  computed: {
    s3Path () {
      if (this.pathMode !== 'FULL_PATH') {
        return this.$store.state.aws_s3
      }
      return ''
    }
  },
  methods: {
    updateValue (value) {
      this.$emit('input', value)
      this.show = false
    }
  }
}
