
import { BIconX, BIconPlay } from 'bootstrap-vue'
export default {
  components: {
    BIconX,
    BIconPlay
  },
  data () {
    return {
      modalDetail: {}
    }
  },
  methods: {
    closeModal () {
      this.$bvModal.hide('attribute_toolstip')
    },
    openVideo (videoUrl) {
      if (videoUrl) {
        window.open(videoUrl, '_blank', 'width=720,height=680')
      }
    }
  }
}
