
import { mapMutations } from 'vuex'
import { BIconX } from 'bootstrap-vue'
export default {
  components: {
    BIconX
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['selectOrderDesignText'],
  data () {
    return {
      show: false,
      header: 'แบบที่เคยออกแบบไว้',
      subHeader: '',
      designList: [],
      prepareDeleteData: {},
      myDesignParams: {}
    }
  },
  methods: {
    getValidationState ({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
    ...mapMutations(['showLoading', 'hideLoading', 'setAlert', 'setAlertWithLine']),
    async getMyDesigns (myDesignParams) {
      this.myDesignParams = myDesignParams
      const designList = await this.$axios.$get('/designs/get-designs-with-size', {
        params: this.myDesignParams
      })
      if (this.myDesignParams.design_type === 'semi-design') {
        designList.data = designList.data.filter(o => parseInt(o.semi_design_id) === parseInt(this.myDesignParams.semi_design_id))
      }
      this.designList = designList.data
    },
    async reloadDesigns () {
      const designList = await this.$axios.$get('/designs/get-designs-with-size', {
        params: this.myDesignParams
      })
      this.designList = designList.data
      if (this.myDesignParams.design_type === 'semi-design') {
        this.designList = this.designList.filter(o => parseInt(o.semi_design_id) === parseInt(this.myDesignParams.semi_design_id))
      }
    },
    slideLeft (el, width) {
      const content = document.querySelector(el)
      const translate = 0
      content.scrollLeft -= width
      content.style.transform = 'translateX(' + translate + 'px' + ')'
    },
    slideRight (el, width) {
      const content = document.querySelector(el)
      const translate = 0
      content.scrollLeft += width
      content.style.transform = 'translateX(' + translate + 'px' + ')'
    },
    deleteConfirmation (data) {
      this.prepareDeleteData = data
      this.$bvModal.show('deleteConfirmationModal')
    },
    async confirmDelete (id) {
      this.showLoading()
      this.$bvModal.hide('deleteConfirmationModal')
      await this.$axios.delete(`/designs/delete-design/${id}`).then(() => {
        this.prepareDeleteData = { id: null, name: '' }
        this.setAlert({
          show: true,
          message: 'ลบข้อมูลสำเร็จ',
          header: 'แจ้งเตือน'
        })
      })
      this.$axios.$get('/designs/get-designs-with-size', {
        params: this.myDesignParams
      }).then((res) => {
        this.designList = res.data
        if (this.myDesignParams.design_type === 'semi-design') {
          this.designList = this.designList.filter(o => parseInt(o.semi_design_id) === parseInt(this.myDesignParams.semi_design_id))
        }
        this.hideLoading()
      })
    },
    async createNewDesdign () {
      this.showLoading()
      const resDesign = await this.$axios.$post('/designs/create-designs', this.myDesignParams)
      const design = resDesign.data
      const res = await this.$axios.$get('/designs/get-designs-with-size', {
        params: this.myDesignParams
      })
      this.designList = res.data
      if (this.myDesignParams.design_type === 'semi-design') {
        this.designList = this.designList.filter(o => parseInt(o.semi_design_id) === parseInt(this.myDesignParams.semi_design_id))
      }
      this.edit(design)
      this.hideLoading()
    },
    edit (design) {
      this.$emit('openModalDesign', design)
    },
    order (design) {
      this.$emit('orderDesign', design)
      this.show = false
    }
  }
}
