
import { mapMutations } from 'vuex'
import { ValidationProvider } from 'vee-validate'
// import { BIconX } from 'bootstrap-vue'
export default {
  components: {
    // BIconX,
    // ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      msg: '',
      header: 'ยืนยันอีเมล์',
      show: false,
      phone: '',
      code: '',
      verify: 1,
      verifyCode: '',
      register: false,
      login: false,
      email: ''
    }
  },
  methods: {
    getValidationState ({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
    ...mapMutations(['showLoading', 'hideLoading', 'setAlert']),
    isNumber (evt) {
      const charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    async confirmMail () {
      this.showLoading()
      const url = 'email-update'
      const result = await this.$axios.$post(url,
        {
          email: this.email
        }
      )
      if (result.status === true) {
        this.verify = 3
      } else if (result.status === false) {
        this.setAlert({
          show: true,
          message: result.message,
          header: 'แจ้งเตือน'
        })
      } else { }
      this.hideLoading()
    },
    complete () {
      this.show = false
      this.$parent.goToCheckout(false)
      this.verify = 1
    }
  }
}
