
import { mapMutations } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

// import firebase from 'firebase/app'
// import 'firebase/auth'
import { sendPasswordResetEmail } from 'firebase/auth'

import { BIconX } from 'bootstrap-vue'
export default {
  components: {
    BIconX,
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      error_message: '',
      email: '',
      password: '',
      name: '',
      phone: '',
      accepted: 'false',
      rememberme: 'true',
      showPassword: false,
      authenType: 'signin',
      cookieAccept: true
    }
  },
  computed: {
    // ...mapGetters({
    //   authUser: 'firebaseUser/getAuthUser'
    // })
    // cookieAccept () {
    //   const klaroManager = this.$store.state.klaroManager
    //   const authentication = klaroManager.states.authentication || false
    //   return klaroManager.confirmed && authentication
    // }
  },
  methods: {
    ...mapMutations(['showLoading', 'hideLoading', 'setAlert']),
    getValidationState ({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
    togglePassword () {
      this.showPassword = !this.showPassword
    },
    changeAuthen (to) {
      this.$nextTick(() => {
        if (this.$refs.validateSignin) { this.$refs.validateSignin.reset() }
        if (this.$refs.validateResetPassword) { this.$refs.validateResetPassword.reset() }
        if (this.$refs.validateSignup) { this.$refs.validateSignup.reset() }
      })
      this.authenType = to
    },
    resetPassword () {
      this.$refs.validateResetPassword.validate().then((isValid) => {
        if (isValid) {
          this.showLoading()
          /* ajax call if valid */
          const that = this
          sendPasswordResetEmail(
            this.email)
            .then(function () {
              that.hideLoading()
              // Password reset email sent.
              that.authenType = 'resetpassword_result'
            })
            .catch(function (error) {
              that.hideLoading()
              that.error_message = error.message
              that.$refs['modal-authen-error'].show()
              // Error occurred. Inspect error.code.
            })
        } else {
        /* something else if not valid */
        }
      })
    },
    signin () {
      if (this.cookieAccept) {
        this.$refs.validateSignin.validate().then((isValid) => {
          if (isValid) {
            this.showLoading()
            this.$store.dispatch('authenticateUser', {
              isLogin: true,
              email: this.email,
              password: this.password,
              rememberme: this.rememberme,
              name: null,
              phone: null
            })
              .then((res) => {
                this.hideLoading()
                if (res.status === 200) {
                  this.email = ''
                  this.password = ''
                  this.$refs['modal-authen'].hide()
                  this.$router.push(this.localePath('/'))
                } else {
                  this.error_message = res.message
                  this.$refs['modal-authen-error'].show()
                }
              })
          } else {
          /* something else if not valid */
          // console.log('invalid')
          }
        })
      } else {
        this.setAlert({
          show: true,
          message: 'Please accept cookies policy before login.',
          header: 'แจ้งเตือน'
        })
      }
    },
    signInGoogle () {
      if (this.cookieAccept) {
        this.showLoading()
        this.$store.dispatch('authenticateUserGoogle')
          .then((res) => {
            this.hideLoading()
            if (res.status === 200) {
              this.email = ''
              this.password = ''
              this.$refs['modal-authen'].hide()
              this.$router.push(this.localePath('/'))
            } else {
              this.error_message = res.message
              this.$refs['modal-authen-error'].show()
            }
          })
      } else {
        this.setAlert({
          show: true,
          message: 'Please accept cookies policy before login.',
          header: 'แจ้งเตือน'
        })
      }
    },
    signInFacebook () {
      if (this.cookieAccept) {
        this.showLoading()
        this.$store.dispatch('authenticateUserFacebook')
          .then((res) => {
            this.hideLoading()
            if (res.status === 200) {
              this.email = ''
              this.password = ''
              this.$refs['modal-authen'].hide()
              this.$router.push(this.localePath('/'))
            } else {
              this.error_message = res.message
              this.$refs['modal-authen-error'].show()
            }
          })
      } else {
        this.setAlert({
          show: true,
          message: 'Please accept cookies policy before login.',
          header: 'แจ้งเตือน'
        })
      }
    },
    signup () {
      this.$refs.validateSignup.validate().then((isValid) => {
        if (isValid) {
          this.showLoading()
          this.$store.dispatch('authenticateUser', {
            isLogin: false,
            email: this.email,
            password: this.password,
            name: this.name,
            phone: this.phone,
            accepted: this.accepted,
            rememberme: false
          })
            .then((res) => {
              this.hideLoading()
              if (res.status === 200) {
                this.email = ''
                this.password = ''
                this.$refs['modal-authen'].hide()
                this.$router.push(this.localePath('/'))
              } else {
                this.error_message = res.message
                this.$refs['modal-authen-error'].show()
              }
            })
        } else {
        /* something else if not valid */
          // console.log('invalid')
        }
      })
    },
    show () {
      this.$refs['modal-authen'].show()
    }
  }
}
