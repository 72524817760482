
import { mapMutations } from 'vuex'
import { BIconX } from 'bootstrap-vue'
import Consent from '../Consent.vue'
export default {
  components: {
    BIconX,
    Consent
  },
  data () {
    return {
      msg: '',
      header: 'ยืนยันตัวตน',
      show: false,
      phone: '',
      code: '',
      verify: 1,
      verifyCode: '',
      register: false,
      login: false,
      email: '',
      otpOption: 'phone',
      showOtpOption: false
    }
  },
  methods: {
    ...mapMutations(['showLoading', 'hideLoading', 'setAlert']),
    isNumber (evt) {
      const charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    async otp () {
      this.showLoading()
      const url = (this.login === true) ? 'otp-login' : 'otp'
      const result = await this.$axios.$post(url,
        {
          otpOption: this.otpOption,
          phone: this.phone
        }
      )
      if (result.status === true) {
        this.verify = 2
      } else if (result.status === false) {
        this.setAlert({
          show: true,
          message: result.message,
          header: 'แจ้งเตือน'
        })
      } else {
        this.setAlert({
          show: true,
          message: result,
          header: 'ไม่สามารถทำรายการได้'
        })
      }
      this.hideLoading()
    },
    async verifyOTP () {
      this.showLoading()
      // console.log(this)
      const url = (this.login === true) ? 'verify-otp-login' : 'verify-otp'
      const result = await this.$axios.$post(url,
        {
          phone: this.phone,
          code: this.code,
          email: this.email,
          otpOption: this.otpOption
        }
      )
      if (result.status === true) {
        if (this.login === true) {
          this.$store.dispatch('authenticateSmsUser', result)
        } else {
          setTimeout(() => {
            this.complete()
          }, 2000)
        }
        this.verify = 3
      } else if (result.status === false) {
        this.setAlert({
          show: true,
          message: result.message,
          header: 'แจ้งเตือน'
        })
      } else { }
      this.hideLoading()
    },
    complete () {
      this.show = false
      if (this.login === true && localStorage.getItem('click-login-from-page') === 'customize-product-design-id') {
        this.showLoading()
        const routeOpt = { name: localStorage.getItem('click-login-from-page') }
        const paramsJson = JSON.parse(localStorage.getItem('click-login-from-page-params'))
        if (localStorage.getItem('click-login-from-page-params') !== '') {
          routeOpt.params = paramsJson
        }
        this.$router.push(this.localeLocation(routeOpt))
      } else if (this.login === true || this.register) {
        this.showLoading()
        this.$router.push(this.localeLocation({ name: 'index' }))
      } else {
        this.$parent.goToCheckout(false)
      }
    }
  }
}
