
import { BIconX } from 'bootstrap-vue'
export default {
  components: {
    BIconX
  },
  data () {
    return { msg: '', header: '', show: false, statusMsg: '' }
  },
  destroyed () {
    this.statusMsg = ''
  },
  mounted () {
    // this.$refs.shareUrlText.focus()
  },
  methods: {
    copyToClipboard () {
      this.$refs.shareUrlText.focus()
      this.$refs.shareUrlText.select()
      this.$refs.shareUrlText.setSelectionRange(0, this.msg.length)
      this.$clipboard(this.msg)
      this.statusMsg = 'URL Copied'
      setTimeout(() => {
        this.statusMsg = ''
      }, 2000)
    }
  }
}
