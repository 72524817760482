
import Swal from 'sweetalert2'
import { mapMutations } from 'vuex'
export default {
  props: {
    phone: {
      type: [String],
      required: true
    },
    consents: {
      type: [Object],
      required: true
    },
    isLine: {
      type: [Boolean],
      required: false
    }
  },
  data () {
    return {
      code: '',
      otpOption: 'phone',
      confirmOtpSuccess: false,
      login: true,
      register: false
    }
  },
  methods: {
    ...mapMutations(['showLoading', 'hideLoading', 'setAlert']),
    modalShowConfirmOtp () {
      this.code = ''
      this.$bvModal.show('modal-confirm-otp')
      this.$bvModal.hide('modal-confirm-otp-success')
    },
    modalShowOtpSuccess () {
      this.code = ''
      this.$bvModal.hide('modal-confirm-otp')
      this.$bvModal.show('modal-confirm-otp-success')
    },
    isNumber (evt) {
      const charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    async verifyOTP () {
      if (this.code.length !== 6) {
        return
      }
      this.showLoading()
      const url = 'verify-otp-login'
      try {
        const result = await this.$axios.$post(url, {
          phone: this.phone,
          code: this.code,
          email: this.email,
          otpOption: this.otpOption,
          accept_term: this.consents.accepted ? this.consents.accepted : 0
        })
        this.hideLoading()
        // console.log('confirmOTP', result)
        if (result.status === true) {
          this.$bvModal.hide('modal-confirm-otp')
          this.$bvModal.hide('modal-confirm-otp-success')
          this.$emit('update:confirmed', true)
          this.$emit('updateLineUser')
          if (this.login === true) {
            this.$store.dispatch('authenticateSmsUser', result)
          } else {
            setTimeout(() => {
              this.complete()
            }, 2000)
          }
          if (!this.isLine) {
            // console.log('showModalll')
            this.modalShowOtpSuccess()
          }
          return true
        } else if (result.status === false) {
          this.setAlert({
            show: true,
            message: result.message,
            header: 'แจ้งเตือน'
          })
        } else {
          // console.log('showMMM')
          this.modalShowConfirmOtp()
        }
      } catch (e) {
        console.log('error', e.message)
        Swal.fire({
          title: 'รหัสยืนยันไม่ถูกต้อง ',
          icon: 'error'
        })
      }
      this.hideLoading()
    },
    complete () {
      this.show = false
      if (this.login === true && localStorage.getItem('click-login-from-page') === 'customize-product-design-id') {
        this.showLoading()
        const routeOpt = { name: localStorage.getItem('click-login-from-page') }
        const paramsJson = JSON.parse(localStorage.getItem('click-login-from-page-params'))
        if (localStorage.getItem('click-login-from-page-params') !== '') {
          routeOpt.params = paramsJson
        }
        this.$router.push(this.localeLocation(routeOpt))
      } else if (this.login === true) {
        this.showLoading()
        const backUrl = localStorage.getItem('back_url')
        if (backUrl) {
          this.$router.push(backUrl)
        } else {
          this.$router.push('/')
        }
      } else {
        this.$parent.goToCheckout(false)
      }
    }
  }
}
