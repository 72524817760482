
import { BIconX } from 'bootstrap-vue'
export default {
  components: {
    BIconX
  },
  data () {
    return { show: false, imgs: [], factory: '' }
  }
}
