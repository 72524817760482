
import { BIconX } from 'bootstrap-vue'
import { mapMutations } from 'vuex'
export default {
  components: {
    BIconX
  },
  data () {
    return { show: false, sampleProduct: {} }
  },
  methods: {
    ...mapMutations(['showLoading']),
    linkToProduct (pkgType, mappingInputId, productId) {
      if (pkgType !== 4) {
        return { name: 'customize-product-design-id', params: { design: true, id: mappingInputId }, query: { productId } }
      } else {
        return { name: 'products-detail-id', params: { id: mappingInputId }, query: { productId } }
      }
    }
  }
}
